import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import ServicePage from 'templates/servicePage';
import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import WysiwygArea from 'components/content/wysiwygArea';

const ContentContainer = styled(Container)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const GoverningBodies = ({ data }) => {
  const {
    markdownRemark: { 
      frontmatter: { hero },
      html,
    },
  } = data;

  return (
    <ServicePage
      data={data}
      heroImage={(
        <Img fluid={hero.image.src.childImageSharp.fluid} alt={hero.image.alt} />
      )}
    >

      <ContentContainer pt={[5, 5, 10]} pb={[10, 10, `100px`]}>
        <MaxWidth>
          <WysiwygArea>
            {html}
          </WysiwygArea>
        </MaxWidth>
      </ContentContainer>

    </ServicePage>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...ServicePageFragment
      ...ServiceHeroFragment
      html
    }
  }
`;

GoverningBodies.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GoverningBodies;
